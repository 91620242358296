<template>
  <div class="dashboard">
    <Loader v-if="performingRequest && (!usersByProximity || usersByProximity.length == 0)" />
    <div class="dashboard__container pb-0">
      <div class="dashboard__container--header mb-2">
        <h1>Employees</h1>
        <span>
          <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': showActive, 'btn__outlined btn__small': !showActive }" @click="showActiveEmployees()">Active</button>

          <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': showOnboarding, 'btn__outlined btn__small': !showOnboarding }" @click="showOnboardingEmployees()">Onboarding</button>

          <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': showTerminated, 'btn__outlined btn__small': !showTerminated }" @click="showTerminatedEmployees()">Terminated</button>

          <router-link :to="{name: 'users'}">
            <button class="btn btn__small btn__outlined ml-3">Show Users</button>
          </router-link>

        </span>
        
      </div>

      <div class="dashboard__container--body">
        <div class="dashboard__container--body--col" style="width:100%;">

          <UsersTableAlt :filteredResults="activeEmployees" v-if="showActive" />
          <UsersTableAlt :filteredResults="onBoardingEmployees" v-if="showOnboarding" />
          <UsersTableAlt :filteredResults="terminatedEmployees" v-if="showTerminated" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UsersTableAlt from '@/components/UsersTableAlt.vue'
import ExportService from "@/services/ExportService"
import router from '@/router'

export default {
  name: 'employees',
  data() {
    return {
      batch: [],
      newGroup: null,
      showTextBox: false,
      showGroupBox: false,
      textMessage: '',
      performingRequest: false,
      performingRequest2: false,
      performingRequest3: false,
      showActive: true,
      showOnboarding: false,
      showTerminated: false,
    };
  },
  computed: {
    ...mapState(['employees', 'currentUser', 'userProfile']),
    activeEmployees() {
      if (this.showActive) {
        return this.employees.filter(employee => {
          return (((employee.hireDate && employee.hireDate != null) && !employee.terminationDate) && (employee.employmentStatus == 'Active' || employee.employmentStatus == 'On-Leave'))
        })
      }
    },
    onBoardingEmployees() {
      if (this.showOnboarding) {
        return this.employees.filter(employee => {
          return (!employee.employmentStatus || (employee.employmentStatus != 'Active' && employee.employmentStatus != 'Terminated' && employee.employmentStatus != 'Resigned'))
        })
      }
    },
    terminatedEmployees() {
      if (this.showTerminated) {
        return this.employees.filter(employee => {
          return (employee.terminationDate && employee.terminationDate != null)
        })
      }
    }
    
  },
  components: {
    Loader,
    UsersTableAlt
  },
  created () {
    this.$store.dispatch("getEmployees")
  },
  methods: {
    showActiveEmployees() {
      this.showActive = true
      this.showOnboarding = false
      this.showTerminated = false
    },
    showOnboardingEmployees() {
      this.showActive = false
      this.showOnboarding = true
      this.showTerminated = false
    },
    showTerminatedEmployees() {
      this.showActive = false
      this.showOnboarding = false
      this.showTerminated = true
    },
  },
  beforeDestroy () {
    this.newGroup = ''
    delete this.newGroup
    this.textMessage = ''
    delete this.textMessage
    this.performingRequest = ''
    delete this.performingRequest
    this.performingRequest2 = ''
    delete this.performingRequest2
    this.performingRequest3 = ''
    delete this.performingRequest3
    this.batch = ''
    delete this.batch
    this.showGroupBox = ''
    delete this.showGroupBox
    this.showTextBox = ''
    delete this.showTextBox
    this.$store.dispatch('clearEmployees')
  }
}
</script>