<template>
	<div class="whiteBack pa-0" style="overflow: auto; width:100%;">


        <vue-good-table
        @on-selected-rows-change="selectionChanged"
          v-if="filteredResults && filteredResults.length >= 1"
            :columns="columns"
            :rows="filteredResults"
            styleClass="vgt-table striped"
            :search-options="{
              enabled: true,
              searchFn: mySearchFn,
              placeholder: 'Search this table',
            }"
            :select-options="{
              enabled: true,
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              selectOnCheckboxOnly: true,
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 1000,
            }"
            >
            <div slot="selected-row-actions">

              <button class="btn btn__small btn__dark mr-4 mt-1" @click="exportSelected()">
                Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <button v-show="!showTextBox" class="btn btn__small btn__dark mr-4 mt-1" @click="showBox()">
                Send Message <i class="fa-solid fa-comment-sms ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <button v-show="showTextBox" class="btn btn__small btn__dark mr-4 mt-1" @click="hideBox()">
                Hide Message <i class="fa-solid fa-comment-sms ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <button v-show="!showGroupBox" class="btn btn__small btn__dark mr-4 mt-1" @click="showGroup()">
                Add to Group <i class="fa-regular fa-user-group ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <button v-show="showGroupBox" class="btn btn__small btn__dark mr-4 mt-1" @click="hideGroup()">
                Hide Groups <i class="fa-regular fa-user-group ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <transition name="fade">
                <div class="pa-4" v-if="showGroupBox">
                  <label for="">Add Users to Group:</label>
                    <v-select :options="groups" label="title" v-model="newGroup"  />
      
                  <button v-if="newGroup" class="btn btn__primary btn__small mt-3 mb-3" @click="addToGroup()">Submit
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequest3">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
              </transition>
              <transition name="fade">
                <div class="pa-3" v-if="showTextBox">
                  <textarea id="message" class="mt-3" cols="30" rows="4" style="min-width: 300px;" v-model="textMessage"></textarea>
                  
                  <!-- <input type="text" placeholder="Type Message" v-model="textMessage" style="min-width: 300px;" /> -->
                  <button v-if="textMessage" class="btn btn__primary btn__small mt-3 mb-3" @click="sendTextMessage()">Send
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequest3">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
              </transition>
            </div>

            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'photoUrl'">
                <span v-if="props.row.photoUrl">
                  <img :src="(props.row.photoUrl || `https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa`)" alt="" style="width: 5rem; height:5rem; border-radius: 50%; padding: 0.25rem;">
                </span>
              </span>
              <span v-else-if="props.column.field == 'fullName'">
                <span class="flex">
                  <router-link :to="'/users/' + props.row.id" class="darkLink">
                    <h5 v-if="!props.row.blacklist || (props.row.blacklist && props.row.blacklist.length == 0)">
                    {{props.row.firstName}} {{props.row.lastName}}
                    </h5>
                    <h5 v-if="props.row.blacklist && props.row.blacklist.length >= 1" class="danger">
                    {{props.row.firstName}} {{props.row.lastName}}
                    </h5>


                  </router-link>
                  <span class="caption">
                    <span v-if="props.row.beeline"><i class="fa-duotone fa-bee ml-3" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                    <span v-if="props.row.hot"><i class="fa-solid fa-fire-flame ml-3" style="color:#E25822;"></i></span>
                    <span v-if="props.row.lead"><i class="fa-solid fa-badge-check ml-3 blueHue"></i></span>
                    <span v-if="props.row.groups && props.row.groups.length >= 1">
                      <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.groups)">
                      <i class="fa-solid fa-user-group blueHue ml-3"></i>
                      <template slot="popover" style="display: inline;">
                        Member Of:
                        <div v-for="(item, index) in activeTitles">
                          {{item}} 
                        </div>
                        </template>
                      </v-popover>
                    </span>

                    <span v-if="props.row.groupApplications && props.row.groupApplications.length >= 1">
                      <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.groupApplications)">
                      <i class="fa-solid fa-user-group orangeHue ml-2"></i>
                      <template slot="popover" style="display: inline;">
                        Applied To:
                        <div v-for="(item, index) in activeTitles">
                          {{item}} 
                        </div>
                        </template>
                      </v-popover>
                    </span>

                    <!-- <span v-if="props.row.groups && props.row.groups.length >= 1"><i class="fa-solid fa-user-group blueHue ml-3"></i></span> -->
                    <span v-if="props.row.id_card_path">
                      <v-popover>
                        <i class="fa-solid fa-address-card ml-3" style="color:#333333;"></i>
                        <template slot="popover" style="display: inline;">
                          <img :src="props.row.id_card_path" alt="" style="width:100%;">
                        </template>
                      </v-popover>
                    </span>
                  </span>
                </span>
                <span class="mt-0">
                  <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.rating" v-model="props.row.rating" class="caption"></star-rating>
                </span>
                <div class="flex justify-flex-start mt-0">
                  <span v-if="props.row && props.row.points" class="caption flex align-center">
                    {{props.row.points}} Points
                  </span>
                </div>
                <div class="flex justify-flex-start mt-0 caption">
                  DOB: {{props.row.dob | moment("MM/DD/YYYY") }}
                </div>
                <div class="mt-0 caption" v-if="props.row.hireDate" style="color: #21bcf3;">HD: {{props.row.hireDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-0 caption" v-if="props.row.terminationDate" style="color:#ef4b46;">TD: {{props.row.terminationDate | moment("MM/DD/YYYY")}}</div>
              </span>
              <span v-else-if="props.column.field == 'created'" class="caption">
                {{formatDate(props.row.created)}}
              </span>
              <span v-else-if="props.column.field == 'onboardFlow'">
                <div style="margin-bottom: 1px;">
                    <ContractorStatus :user="props.row" />
                  </div>
                <div>
                  
                  <EmployeeStatus :user="props.row" />
                  
                </div>
              </span>

              <span v-else-if="props.column.field == 'watchlist'">
                 <button v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
                 <button v-if="!isWatchList(props.row)" class="btn btn__icon" @click="addWatch(props.row)"><i class="fa-regular fa-star" style="opacity: 0.6;"></i></button>
              </span>
              <span v-else-if="props.column.field == 'check'">

                <div v-if="props.row.checkOrderResults && props.row.checkOrderResults[0] && !props.row.backgroundCheckResponse">
                  <router-link :to="'/users/' + props.row.id + '/backgroundcheck'">
                    <button class="btn btn__xsmall btn__primary">Review</button>
                  </router-link>
                </div>

                
                <div class="caption" v-if="(props.row.checkInvitations && props.row.checkInvitations[0] && props.row.checkInvitations[0].payload && props.row.checkInvitations[0].payload.applicantinfo) && !props.row.backgroundCheckResponse">{{props.row.checkInvitations[0].payload.applicantinfo.reference}} - 
                  <span v-if="props.row.checkInvitations && props.row.checkInvitations[0] && props.row.checkInvitations[0].payload && props.row.checkInvitations[0].payload.result">
                    {{props.row.checkInvitations[0].payload.result.status}} <span v-if="props.row.checkInvitationResponses && props.row.checkInvitationResponses[0] && props.row.checkInvitationResponses[0].payload">
                       on {{props.row.checkInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}
                    </span>
                  </span>

                </div>
 

                <div v-if="props.row.backgroundCheckResponse">
                  <div class="caption strong">Response: {{props.row.backgroundCheckResponse}}</div>
                </div>

              </span>
              <span v-else-if="props.column.field == 'drug'">

                <div v-if="props.row.drugOrderResults && props.row.drugOrderResults[0] && !props.row.drugCheckResponse">
                  <router-link :to="'/users/' + props.row.id + '/backgroundcheck'">
                    <button class="btn btn__xsmall btn__primary">Review</button>
                  </router-link>
                </div>

                <div class="caption" v-if="(props.row.drugInvitations && props.row.drugInvitations[0] && props.row.drugInvitations[0].payload && props.row.drugInvitations[0].payload.applicantinfo) && !props.row.drugCheckResponse">{{props.row.drugInvitations[0].payload.applicantinfo.reference}} - 
                  <span v-if="props.row.drugInvitations && props.row.drugInvitations[0] && props.row.drugInvitations[0].payload && props.row.drugInvitations[0].payload.result">
                    {{props.row.drugInvitations[0].payload.result.status}} <span v-if="props.row.drugInvitationResponses && props.row.drugInvitationResponses[0] && props.row.drugInvitationResponses[0].payload">
                       on {{props.row.drugInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}
                    </span>
                  </span>

                </div>


                <div v-if="props.row.drugCheckResponse">
                  <div class="caption strong">Response: {{props.row.drugCheckResponse}}</div>
                </div>

              </span>
              <span v-else-if="props.column.field == 'accurate'">
                <div class="caption mb-1" v-if="(props.row && props.row.accurateCheckCreated && props.row.accurateCheckCreated != null) && (!props.row.accurateCheckResponse || props.row.accurateCheckResponse == 'Order Placed')">

                  <!-- Check Ordered: {{props.row.accurateCheckCreated.toDate() | moment("MM/DD/YYYY")}} -->

                  Ordered: {{formatStamp(props.row.accurateCheckCreated)}}
                </div>
                <div class="caption strong" v-bind:class="{ danger: props.row.accurateCheckResponse == 'Failed Do Not Hire' }" v-if="props.row.accurateCheckResponse">Status: {{props.row.accurateCheckResponse}}</div>
              </span>
              <span v-else-if="props.column.field == 'amazon'">
                <div class="caption mb-1" v-if="(props.row && props.row.amazonCheckCreated && props.row.amazonCheckCreated != null) && (!props.row.amazonCheckResponse || props.row.amazonCheckResponse == 'Order Placed')">

                  <!-- Check Ordered: {{props.row.amazonCheckCreated.toDate() | moment("MM/DD/YYYY")}} -->

                Ordered: {{formatStamp(props.row.amazonCheckCreated)}}
                </div>
                <div class="caption strong" v-bind:class="{ danger: props.row.amazonCheckResponse == 'Failed Do Not Hire' }" v-if="props.row.amazonCheckResponse">Status: {{props.row.amazonCheckResponse}}</div>
              </span>
              <span v-else-if="props.column.field == 'onboardStatus'">
                <div v-if="props.row.workerId && props.row.evereeOnboardingComplete" class="caption" style="color:#00C897;">
                  Contractor
                </div>
                <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete" class="caption" style="color:#f0ad4e;">
                  Contractor 
                </div>
                <div v-if="!props.row.workerId" class="caption" style="color:#ef4b46;">
                  Contractor
                </div>
                <div v-if="!props.row.employeeId" class="caption" >
                  <div style="color:#ef4b46;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && !props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#f0ad4e;">Employee</div>
                </div>
                <div v-if="props.row.employeeId && props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#00C897;">Employee</div>
                </div>
              </span>
              <span v-else-if="props.column.field == 'phone'">
                <span v-if="props.row.phone" class="caption">
                  <a :href="'sms:' + props.row.phone" class="darkLink">{{props.row.phone}}</a>
                </span>
              </span>
              <span v-else-if="props.column.field == 'employmentAssignment'">

                <span class="caption" v-if="props.row.employmentAssignment">{{props.row.employmentAssignment.eventName}}</span>
              </span>
              <span v-else-if="props.column.field == 'city'">
                <span class="caption" v-if="props.row.address && props.row.address.city">{{props.row.address.city}}</span>
              </span>
              <span v-else-if="props.column.field == 'state'">
                <span class="caption" v-if="props.row.address && props.row.address.state">{{props.row.address.state}}</span>
              </span>
              <span v-else-if="props.column.field == 'link'">
                <router-link :to="`/users/` + props.row.id" target="_blank">
                  <i class="fas fa-external-link ml-3 mr-3"></i>
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'skills'">
                <span v-if="!props.row.skills || props.row.skills.length == 0">
                  <i class="fad fa-briefcase ml-2 mr-2" style="opacity:50%;"></i>
                </span>
                <span v-if="props.row.skills && props.row.skills.length > 0">
                  <v-popover>
                    <i class="fad fa-briefcase ml-2 mr-2 success"></i>
                    <template slot="popover">
                      <span v-for="z in props.row.skills">{{z.title}} / </span>
                    </template>
                  </v-popover>
                </span>
              </span>
              <span v-else-if="props.column.field == 'blacklist'">
                <span v-if="props.row.blacklist && props.row.blacklist.length >=1" style="display:inline;">
                  <v-popover>
                    <i class="fas fa-exclamation-triangle ml-2 mr-2" style="color:red;"></i>
                    <template slot="popover">
                    <span v-for="z in props.row.blacklist">{{z.title}}</span>
                  </template>
                  </v-popover>
                </span>
              </span>
              
              <span v-else-if="props.column.field == 'action'">
                <span v-if="action && action == 'removeUser'">
                  <button class="icon" v-tooltip="'delete instance'" @click.prevent="deleteUser(props.row, props.index)">
                    <i class="fas fa-trash ml-2 mr-2"></i>
                  </button>
                </span>
                <span v-if="action && action == 'applicants'">
                  <button class="icon" v-tooltip="'accept application'" @click.prevent="acceptApplication(props.row, props.index)">
                    <i class="fa-solid fa-circle-check mr-3" style="color: green"></i>
                  </button>

                  <button class="icon" v-tooltip="'hold application'" @click.prevent="holdApplication(props.row, props.index)">
                    <i class="fa-solid fa-triangle-exclamation mr-3" style="color: goldenrod"></i>
                  </button>


                  <button class="icon" v-tooltip="'delete user'" @click.prevent="deleteApplication(props.row, props.index)">
                    <i class="fa-solid fa-octagon-exclamation" style="color: red"></i>
                  </button>
                  
                </span>
              </span>
              <span v-else-if="props.column.field == 'delete'">
                <button v-if="!props.row.hidden" class="btn btn__dark btn__small mr-2" @click="removeEntry(index)">
                  hide
                </button>
              </span>


               <span v-else>
                <span class="caption">
                {{props.formattedRow[props.column.field]}}
                </span>
              </span>
            </template>


          </vue-good-table>
        </div>
</template>


<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import EmployeeStatus from '@/components/EmployeeStatus.vue'
import ContractorStatus from '@/components/ContractorStatus.vue'
import ExportService from "@/services/ExportService"
import router from '@/router'
import StarRating from 'vue-star-rating'
const fb = require('../firebaseConfig.js')

export default {
  name: 'users',
  props: ['filteredResults'],
  data() {
    return {
      exportBatch: [],
      newGroup: null,
      showTextBox: false,
      showGroupBox: false,
      textMessage: '',
      activeTitles: [],
      performingRequest: false,
      performingRequest2: false,
      performingRequest3: false,
      columns: [
      {
        field: 'photoUrl',
        sortable: false,
        width:'60px',
        tdClass: 'text-center',
      },
      
      {
        label: 'Name',
        field: 'fullName',
        sortable: false,
        width:'280px',
      },
      {
        label: 'Watch',
        field: 'watchlist',
        sortable: false,
        width:'60px',
      },
      {
        label: 'Onboarding',
        field: 'onboardFlow',
        sortable: false,
        width:'160px',
      },
      {
        label: 'Status',
        field: 'employmentStatus',
        width:'120px',
      },
      {
        label: 'Assignment',
        field: 'employmentAssignment',
        width:'160px',
      },
      {
        label: 'Payroll',
        field: 'onboardStatus',
        sortable: false,
        width:'120px',
      },
      {
        label: 'City',
        field: 'city',
        sortable: false,
        width:'100px',
      },
      {
        label: 'State',
        field: 'state',
        sortable: false,
        width:'40px',
      },
      {
        label: 'Joined',
        field: 'created',
        sortable: false,
        width:'80px',
      },
      {
        label: 'Phone',
        field: 'phone',
        sortable: false,
        width:'120px',
      },
      {
        label: 'Status',
        field: 'employmentStatus',
        width:'80px',
      },
      {
        label: 'Check',
        field: 'check',
        sortable: false,
        width:'64px',
      },
      {
        label: 'Drug',
        field: 'drug',
        sortable: false,
        width:'64px',
      },
      {
        label: 'Skills',
        field: 'skills',
        sortable: false,
        width:'40px',
      },
      {
        label: 'DNR',
        field: 'blacklist',
        width: '40px',
        sortable: false,
      },
      // {
      //   label: 'Action',
      //   field: 'action',
      //   sortable: false,
      //   width:'80px',
      // }
    ],

    };
  },

  computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
  components: {
    StarRating,
    ContractorStatus,
    EmployeeStatus
  },
  methods: {
  	isWatchList(row) {
      if (this.userProfile && this.userProfile.userWatchList && this.userProfile.userWatchList.length >= 1) {
        return this.userProfile.userWatchList.includes(row.id)
      } else {
        return false
      }
    },
    async addWatch(row) {
      await this.$store.dispatch('watchUser', row.id)
    },
    async removeWatch(row) {
      await this.$store.dispatch('unWatchUser', row.id)
    },
    mySearchFn(row, col, cellValue, searchTerm) {
      if (searchTerm === "") return true;
      return row.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) || row.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
    }, 
    showGroup() {
      this.showGroupBox = true
    },
    hideGroup() {
      this.showGroupBox = false
    },
    showBox() {
      this.showTextBox = true
    },
    hideBox() {
      this.showTextBox = false
    },
    async addToGroup() {
      this.performingRequest3 = true
      let group = this.newGroup
      await this.exportBatch.forEach(user => {
        this.$store.dispatch("addUserToGroup", {
          group: group,
          user: user
        })
      })
      this.performingRequest3 = false
      this.newGroup = null
      this.showGroupBox = false
    },
    async sendTextMessage () {
      this.performingRequest3 = true
      let message = this.textMessage
      await this.exportBatch.forEach(user => {
        console.log(user)
        if (user.phone) {
          let newMessage = {
            userId: user.id,
            phone: user.phone,
            message: message,
          }
          console.log(newMessage)
          this.$store.dispatch('sendUserMessage', newMessage)
        } 
      })
      this.performingRequest3 = false
      this.textMessage = ''
      this.showTextBox = false
    },
    removeEntry(index) {
      this.filteredResults.splice(index, 1)
    },
    selectionChanged(params) {
    	console.log(params.selectedRows)
      this.exportBatch = params.selectedRows
    },

    
    exportSelected() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
      ]
      const exportItems = [];
      for (var key in this.exportBatch) {
        exportItems.push([
          this.exportBatch[key].firstName || null,
          this.exportBatch[key].lastName || null,
          this.exportBatch[key].email || null,
          this.exportBatch[key].phone || null,
        ])
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YY')
      } else {
        return null
      }
    },
    addCreated(){
      this.$store.dispatch("addCreated")
    },
    clearPopover() {
      return this.activeTitles = []
    },
    showPopover(params){
      console.log(params)
      params.forEach(group => {
        fb.groupsCollection.doc(group).get()
        .then(doc => {
          this.activeTitles.push(doc.data().title)
        })
      })
    },
    formatStamp(stamp) {
      if(stamp) {
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      }
    }
  },
  beforeDestroy () {
    this.activeTitles = ''
    delete this.activeTitles
    this.newGroup = ''
    delete this.newGroup
    this.textMessage = ''
    delete this.textMessage
    this.performingRequest = ''
    delete this.performingRequest
    this.performingRequest2 = ''
    delete this.performingRequest2
    this.performingRequest3 = ''
    delete this.performingRequest3
    this.filteredSkills = ''
    delete this.filteredSkills
    this.searchClient = ''
    delete this.searchClient
    this.exportBatch = ''
    delete this.exportBatch
    this.showGroupBox = ''
    delete this.showGroupBox
    this.showTextBox = ''
    delete this.showTextBox
    this.columns = ''
    delete this.columns
    this.$store.dispatch('clearGroups')
    this.$store.dispatch('clearSkills')
    this.$store.dispatch('clearEmployees')
    this.$store.dispatch("clearUsersByProximity")
    console.log(this)
  }
}
</script>